import React, { useContext, useEffect, useState } from 'react';
import isNode from 'detect-node';
import MainContext from 'Src/app/MainContextProvider';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'Src/app/Styles/Theme';

const Wrapper = styled.div`
  letter-spacing: 0.01em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLink = styled(Link)`
  font-size: 18px;
  line-height: 28px;
  padding: 4px;
  color: ${props => props.theme.primary_petrol};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.secondary_blue_dark};
  }
  ${media.tablet`
    font-size: 16px;
  `};
`;

const ChangeLocaleBtn = () => {
  const {
    state: { lang, username },
    dispatch,
  } = useContext(MainContext);
  const [urlRu, setUrlRu] = useState('');
  const [urlEn, setUrlEn] = useState('');

  const replaceLocation = neededLang => {
    if (!isNode) {
      const { pathname } = window.location;
      let resultUrl = pathname;
      if (neededLang === 'ru' && lang !== 'ru') {
        resultUrl = pathname.replace('/en', '');
      }
      if (neededLang === 'en' && lang !== 'en') {
        resultUrl = pathname.replace(pathname, `/en${pathname}`);
      }

      return resultUrl;
    }
  };

  useEffect(() => {
    const langFromStorage = window.localStorage.getItem('lang');

    if (langFromStorage) {
      dispatch({ type: 'toggleLanguage', payload: langFromStorage });
    } else {
      window.localStorage.setItem('lang', lang);
    }

    setUrlEn(replaceLocation('en'));
    setUrlRu(replaceLocation('ru'));
  }, []);

  return (
    <Wrapper>
      <StyledLink
        id="lang_en"
        to={urlEn}
        state={{ name: username }}
        style={{ pointerEvents: lang === 'en' ? 'none' : 'all' }}
      >
        En
      </StyledLink>
      |
      <StyledLink
        id="lang_ru"
        to={urlRu}
        state={{ name: username }}
        style={{ pointerEvents: lang === 'ru' ? 'none' : 'all' }}
      >
        Ru
      </StyledLink>
    </Wrapper>
  );
};

export default ChangeLocaleBtn;
