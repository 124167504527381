import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'Src/app/Styles/Theme';

export const Wrapper = styled.div`
  transition: all 0.3s;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 70px;
  right: 0;

  &:before {
    content: '';
    display: none;
    position: absolute;
    top: -5px;
    transform: rotate(45deg);
    left: ${props => (props.lang === 'en' ? '70%' : '50%')};
    width: 20px;
    height: 20px;
    background: #004666;
  }

  ${media.tablet`
    width: 250px;
    top: 75px;
    &:before {
      display: block;
      right: -20px;
    }
  `};
`;

export const Menu = styled.div`
  padding: 20px 0;
  position: absolute;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #004666;
`;

export const LinkGatsby = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  font-family: 'VWAGTheAntiqua';
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.02em;
  cursor: pointer;
  color: #ffffff;
  &:hover {
    background: #335f7d;
  }
`;

export const LinkSimple = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  font-family: 'VWAGTheAntiqua';
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.02em;
  cursor: pointer;
  color: #ffffff;
  &:hover {
    background: #335f7d;
  }
`;
