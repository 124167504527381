import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ locale }) {
  const { description, lang, title } = locale;
  const metaDescription = description || '';
  return (
    <StaticQuery
      query={graphql`
        query {
          sticker: file(
            relativeDirectory: { regex: "Instructions/" }
            base: { eq: "sticker03.jpg" }
          ) {
            publicURL
          }
        }
      `}
      render={data => {
        return (
          <>
            <Helmet
              htmlAttributes={{
                lang,
              }}
              title={title}
              meta={[
                {
                  name: `yandex-verification`,
                  content: 'a0fc36bba3e2f8ec',
                },
                {
                  name: `google-site-verification`,
                  content: 'mJ0IxTkBFa1Jp5Rb1cWaHbyP_HvhP5l5F5TQfORkBLA',
                },
                {
                  name: `description`,
                  content: metaDescription,
                },
                {
                  property: `og:title`,
                  content: title,
                },
                {
                  property: `og:image`,
                  content: data.sticker.publicURL,
                },
                {
                  property: `og:description`,
                  content: metaDescription,
                },
                {
                  property: `og:type`,
                  content: `website`,
                },
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
                {
                  name: `twitter:title`,
                  content: title,
                },
                {
                  name: `twitter:description`,
                  content: metaDescription,
                },
                {
                  name: 'cmsmagazine',
                  content: 'c5926dc15d50d2f777c581278b0efe9f',
                },
                {
                  name: 'format-detection',
                  content: 'telephone=no',
                },
              ]}
            >
              {/* Google Tag Manager  */}
              <script>
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer_kdx','GTM-PP65BXM');`}
              </script>
              {/* End Google Tag Manager */}
            </Helmet>
          </>
        );
      }}
    />
  );
}

export default SEO;

SEO.propTypes = {
  locale: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  ).isRequired,
};
