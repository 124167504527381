import React, { useContext } from 'react';
import SEO from 'Common/SEO';
import LocalesText from 'Locales';
import MainContext from 'Src/app/MainContextProvider';

const Display = ({ children }) => {
  const {
    state: { lang },
  } = useContext(MainContext);
  return (
    <>
      <SEO locale={LocalesText[lang].SEO} />
      {children}
    </>
  );
};

export default Display;
