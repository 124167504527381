import React, { useEffect, useContext, useState } from 'react';
import MainContext from 'Src/app/MainContextProvider';
import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import Locales from 'Locales/';

import { Wrapper, Menu, LinkGatsby, LinkSimple } from './StyledComponents';

const DropDown = ({ texts }) => {
  const {
    state: { token, auth, lang, isShowStatisticsInMenu, users },
    dispatch,
  } = useContext(MainContext);

  const [username, setUsername] = useState(null);

  const {
    signIn,
    registration,
    profile,
    statistics,
    main,
    logOut,
    idp,
  } = texts;

  const handleClickOutside = () => dispatch({ type: 'toggleDropDown' });

  const getHref = (page, language = lang) =>
    `/${language === 'en' ? 'en/' : ''}${
      !window.location.href.match(page) ? page : ''
    }`;

  useEffect(() => {
    if (token) {
      const { email, name } = jwtDecode(token);

      setUsername(name);
      dispatch({
        type: 'isShowStatisticsInMenu',
        payload: users.some(item => item.toLowerCase() === email),
      });
    } else {
      setUsername(Locales[lang].Header.Profile.title);
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [token]);

  return token !== null && token ? (
    <Wrapper>
      <Menu>
        {window.location.href.match(/profile|statistics/) && (
          <LinkGatsby to={getHref('')} state={{ username }}>
            {main}
          </LinkGatsby>
        )}

        <LinkSimple
          href={`${process.env.GATSBY_ACCOUNT_URL}/?referrer=testedgenuine&themeid=neutral`}
        >
          {idp}
        </LinkSimple>

        {!window.location.href.match(/profile/) &&
          !window.location.href.match(/statistics/) && (
            <>
              <LinkGatsby to={getHref('profile')} state={{ username }}>
                {profile}
              </LinkGatsby>
              {isShowStatisticsInMenu && (
                <LinkGatsby to={getHref('statistics')} state={{ username }}>
                  {statistics}
                </LinkGatsby>
              )}
            </>
          )}
        {window.location.href.match(/profile/) && isShowStatisticsInMenu && (
          <LinkGatsby to={getHref('statistics')} state={{ username }}>
            {statistics}
          </LinkGatsby>
        )}
        {window.location.href.match(/statistics/) && (
          <LinkGatsby to={getHref('profile')} state={{ username }}>
            {profile}
          </LinkGatsby>
        )}
        <LinkSimple
          onClick={() => {
            if (auth) auth.logout();
            dispatch({ type: 'auth', payload: null });
            dispatch({ type: 'token', payload: null });
            Cookie.set(
              'VW_LOGOUT',
              `${process.env.GATSBY_US_REDIRECT}${lang === 'en' ? '/en' : ''}`,
            );
          }}
        >
          {logOut}
        </LinkSimple>
      </Menu>
    </Wrapper>
  ) : (
    <Wrapper lang={lang}>
      <Menu>
        <LinkSimple
          onClick={() => {
            if (auth) auth.login();
            Cookie.set(
              'VW_AUTH',
              `${window.location.href}${
                !window.location.href.match('profile') ? 'profile' : ''
              }`,
            );
          }}
        >
          {signIn}
        </LinkSimple>
        <LinkSimple
          onClick={() => {
            if (auth) auth.register();
            Cookie.set(
              'VW_AUTH',
              `${window.location.href}${
                !window.location.href.match('profile') ? 'profile' : ''
              }`,
            );
          }}
        >
          {registration}
        </LinkSimple>
      </Menu>
    </Wrapper>
  );
};

export default DropDown;
