import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { media } from 'Src/app/Styles/Theme';
import MainContext from 'Src/app/MainContextProvider';
import Locales from 'Locales/';
import IconCustomer from 'Src/assets/img/ico/icon_customer.svg';
import IconClose from 'Src/assets/img/ico/icon_menu-close.svg';
import useMobile from 'Components/Common/Hooks/useIsMobile';
import DropDown from '.';

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.01em;
  ${media.tablet`
    margin-right: 0;
  `}
  span {
    padding: 0 8px;
  }
  img {
    width: 30px;
    ${media.tablet`
      width: 24px;
  `}
  }
`;

const Title = styled.span`
  display: none;
  white-space: nowrap;
  svg {
    position: relative;
    top: -2px;
  }
  ${media.tablet`
    display: block;
  `}
`;

const ProfileButton = ({ token }) => {
  const {
    state: { lang, showDropDown, username },
    dispatch,
  } = useContext(MainContext);

  const [name, setName] = useState(null);
  const isMobile = useMobile();

  useEffect(() => {
    setName(token ? jwtDecode(token).name : username);
  }, [token]);

  return (
    <>
      <Wrapper onClick={() => dispatch({ type: 'toggleDropDown' })}>
        <img
          src={showDropDown && isMobile ? IconClose : IconCustomer}
          alt="icon"
        />
        <Title>
          <span>{name || Locales[lang].Header.Profile.title}</span>
          <svg width="8" height="6" viewBox="0 0 8 6" fill="none">
            <path
              d="M3.808 5.41344C3.706 5.41344 3.605 5.38245 3.518 5.32046L0 1.81115L0.58 0.997314L3.807 4.29866L6.998 0.999313L7.582 1.81015L4.1 5.31946C4.013 5.38245 3.91 5.41344 3.808 5.41344Z"
              fill="#006384"
            />
          </svg>
        </Title>
      </Wrapper>
      {showDropDown && <DropDown texts={Locales[lang].Header.DropDown} />}
    </>
  );
};

export default ProfileButton;
