import React, { useContext } from 'react';
import MainContext from 'Src/app/MainContextProvider';
import styled from 'styled-components';
import useMobile from 'Components/Common/Hooks/useIsMobile';
import LogoTG from 'Src/assets/img/ico/logo_tg.svg';
import { media } from 'Src/app/Styles/Theme';
import Logos from './Logos';
import ChangeLocaleBtn from './ChangeLocaleBtn';
import ProfileButton from './DropDown/ProfileButton';

const Wrapper = styled.div`
  position: relative;
  z-index: 20;
  width: 100%;
  display: flex;
  justify-content: ${props =>
    props.page === 'main' ? 'flex-end' : 'space-between'};
  margin: 0 auto;
  padding: 16px;
  ${media.tablet`
    padding: 20px 16px;
    justify-content: space-between;
  `};
  ${media.laptop`
    width: ${props => props.page !== 'main' && '944px'};
    height: 147px;
  `};
  ${media.desktop`
    width: 1224px;
  `};
  ${media.widescreen`
    width: 1384px;
  `};
  ${media.ultrawidescreen`
    width: 1416px;
  `};
`;

const Menu = styled.div`
  min-width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: ${props => props.mobile && '100%'};
  ${media.tablet`
    flex-direction: column;
    justify-content: space-around;
  `};
`;

const Logo = styled.img`
  width: 60px;
  ${media.tablet`
    width: 130px;
  `};
  ${media.widescreen`
    width: 150px;
  `};
`;

const Header = ({ page }) => {
  const isMobile = useMobile();

  const {
    state: { token },
  } = useContext(MainContext);

  return (
    <Wrapper page={page}>
      {page === 'main' && <Logos />}
      {(page === 'profile' || page === 'statistics') && (
        <a href={process.env.GATSBY_US_REDIRECT}>
          <Logo src={LogoTG} alt="Logo" />
        </a>
      )}
      <Menu page={page} mobile={isMobile}>
        <ProfileButton token={token} />
        <ChangeLocaleBtn />
      </Menu>
    </Wrapper>
  );
};

export default Header;
