import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';
import Theme from 'Src/app/Styles/Theme';

const checkMobile = () =>
  typeof window !== 'undefined' && window.innerWidth < Theme.breakpoints.md;

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(checkMobile());
  function handleChange() {
    if (checkMobile() !== isMobile) {
      setIsMobile(checkMobile());
    }
  }
  const myThrottle = throttle(handleChange, 300);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', myThrottle);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', myThrottle);
      }
    };
  });
  return isMobile;
};

export default useMobile;
