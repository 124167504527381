import React, { useContext } from 'react';
import styled from 'styled-components';
import MainContext from 'Src/app/MainContextProvider';
import { media } from 'Src/app/Styles/Theme';
import { StaticQuery, graphql } from 'gatsby';
import vwLogo from 'Src/assets/img/Header/TopBar/vw.svg';
import vwCommercialLogoRu from 'Src/assets/img/Header/TopBar/vw_com_ru.svg';
import vwCommercialLogoEn from 'Src/assets/img/Header/TopBar/vw_com_en.svg';

const Link = styled.a`
  width: 62px;
  display: none;
  &:hover {
    opacity: 0.8;
  }
  img {
    width: 100%;
  }
  ${media.tablet`
    width: 104px;
    display: block;
  `};
  ${media.widescreen`
    width: 120px;
  `};
`;

const Logos = () => {
  const {
    state: { lang },
  } = useContext(MainContext);

  return (
    <StaticQuery
      query={graphql`
        query {
          SkodaLogo: file(
            relativeDirectory: { regex: "Header/TopBar/" }
            base: { eq: "skoda.png" }
          ) {
            publicURL
          }
          AudiLogo: file(
            relativeDirectory: { regex: "Header/TopBar/" }
            base: { eq: "audi.png" }
          ) {
            publicURL
          }
        }
      `}
      render={data => {
        return (
          <>
            <Link href="https://www.volkswagen.ru/" target="_blank">
              <img src={vwLogo} alt="vw logo" />
            </Link>
            <Link href="http://www.skoda-avto.ru/" target="_blank">
              <img src={data.SkodaLogo.publicURL} alt="skoda logo" />
            </Link>
            <Link href="https://www.audi.ru/" target="_blank">
              <img src={data.AudiLogo.publicURL} alt="audi logo" />
            </Link>
            <Link href="https://www.volkswagen-commercial.ru/" target="_blank">
              {lang === 'ru' ? (
                <img src={vwCommercialLogoRu} alt="vw commercial logo en" />
              ) : (
                <img src={vwCommercialLogoEn} alt="vw commercial logo ru" />
              )}
            </Link>
          </>
        );
      }}
    />
  );
};

export default Logos;
